
import { computed, defineComponent, ref } from "vue";
import Img from "@/components/general/Img.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import NoteViewField from "@/components/users/userProfile/fields/NotesCardFields/NoteViewField.vue";
import NoteEditField from "@/components/users/userProfile/fields/NotesCardFields/NoteEditField.vue";
import { fireToast } from "@/core/helpers/swal";
import DeleteModal from "@/components/general/DeleteModal.vue";
import { isCrewBoss } from "@/components/jobs/helpers";

interface JobNoteFormData {
  note: string;
}

interface Commentator {
  id: number;
  name: string;
  photo_url: string;
  roles: string[];
}

interface SelectedNote {
  id: number;
  text: string;
}

export interface JobNote {
  id: number;
  comment: string;
  created_at: string;
  is_pinned: boolean;
  commentator: Commentator;
}

export default defineComponent({
  methods: { isCrewBoss },
  components: {
    DeleteModal,
    Img,
    Field,
    Form,
    ErrorMessage,
    NoteViewField,
    NoteEditField
  },
  name: "job-notes",
  setup: function() {
    const store = useStore();
    const currentUser = computed(() => store.getters.currentUser);
    const job = computed(() => store.getters["JobModule/item"]);
    const saving = ref<boolean>(false);
    const formComponent = ref();
    const selectedNoteData = ref<SelectedNote | null>(null);
    const areGettingEdited = ref<number[]>([]);

    const selectedNoteId = ref<number | null>(null);

    function setIsSaving(value: boolean): void {
      saving.value = value;
    }

    function handleOpenDeleteModal(noteId: number): void {
      selectedNoteId.value = noteId;
    }

    const notes = computed<JobNote[]>((): JobNote[] => {
      if (job.value && job.value.notes) {
        return job.value.notes as JobNote[];
      }
      return [] as JobNote[];
    });

    const validationSchema = Yup.object().shape({
      note: Yup.string().required("Please enter the note.")
    });

    function saveNote(formData: JobNoteFormData): void {
      const note = formData.note;
      if ("" === note.trim()) {
        return;
      }
      setIsSaving(true);
      store
        .dispatch("JobModule/saveJobNote", note)
        .then(() => {
          formComponent.value.resetForm();
          setIsSaving(false);
          fireToast("Note added successfully.", true);
        })
        .catch(() => {
          fireToast("An error occurred.", false);
          setIsSaving(false);
        });
    }

    function deleteNote(): void {
      store
        .dispatch("NotesModule/destroyData", {
          noteID: selectedNoteId.value
        })
        .then(() => {
          store.dispatch("JobModule/clearNote", selectedNoteId.value);
          fireToast("Note deleted successfully", true);
        });
    }

    function handleEditNote(noteData: SelectedNote): void {
      selectedNoteData.value = noteData;
    }

    function setIsEdited(noteId: number): void {
      if (areGettingEdited.value.includes(noteId)) {
        const index = areGettingEdited.value.findIndex(i => i === noteId);
        if (-1 !== index) {
          areGettingEdited.value.splice(index, 1);
        }
      } else {
        areGettingEdited.value.push(noteId);
      }
    }

    function saveEditedNote(newText: SelectedNote) {
      if (selectedNoteData.value?.id) {
        setIsEdited(selectedNoteData.value?.id);
        store
          .dispatch("NotesModule/update", {
            id: selectedNoteData.value?.id,
            comment: newText
          })
          .then(() => {
            store.dispatch("JobModule/updateNote", {
              noteId: selectedNoteData.value?.id,
              content: newText
            });
            setIsEdited(selectedNoteData.value?.id as number);
            selectedNoteData.value = null;
            fireToast("Note updated successfully", true);
          })
          .catch(() => {
            setIsEdited(selectedNoteData.value?.id as number);
            selectedNoteData.value = null;
            fireToast("Error updating note", false);
          });
      }
    }

    function pinNote(noteId: number): void {
      setIsEdited(noteId);
      store
        .dispatch("JobModule/pinNote", noteId)
        .then(() => {
          setIsEdited(noteId);
        })
        .catch(() => {
          setIsEdited(noteId);
        });
    }

    return {
      handleOpenDeleteModal,
      validationSchema,
      selectedNoteData,
      areGettingEdited,
      selectedNoteId,
      saveEditedNote,
      handleEditNote,
      formComponent,
      currentUser,
      deleteNote,
      saveNote,
      pinNote,
      saving,
      notes,
      job
    };
  }
});
